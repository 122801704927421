<template>
  <BaseFieldset title="App-gebruikers">
    <form @submit.prevent="uploadFile">
      <material-alert
        v-show="results"
        color="success"
      >
        <div
          v-for="(value, name) in results"
          :key="`${name}_${value}`"
        >
          {{ name }}: {{ value || 0 }}
        </div>
      </material-alert>
      
      <material-alert
        v-show="uploading"
        color="info"
      >
        Uploading {spinner gif :P}
      </material-alert>

      <material-alert
        v-show="error.length"
        color="danger"
      >
        {{error}}
      </material-alert>

      <label 
        for="importUsersFile"
      >
        File for importing only users
      </label>

      <material-input
        id="importUsersFile"
        :disabled="uploading"
        type="file"
        name="importUsersFile"
        @change="handleFileUpload( $event, 'users' )"
      />
      <br />

      <label
        for="importCompaniesAndUsersFile"
      >
        File for importing companies and users
      </label>

      <material-input
        id="importCompaniesAndUsersFile"
        :disabled="uploading"
        type="file"
        name="importCompaniesUsersFile"
        @change="handleFileUpload( $event, 'companies-users' )"
      />

      <material-button
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
      >
        upload
      </material-button>
    </form>
  </BaseFieldset>
</template>

<script>
import store from '@/store';
import BaseFieldset from '@/components/Forms/BaseFieldset.vue';
import MaterialAlert from "@/components/MaterialAlert.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";

export default {
  name: "Dashboard",

  components: {
    BaseFieldset,
    MaterialAlert,
    MaterialButton,
    MaterialInput,
  },

  data: () => ({
    error: '',
    uploading: false,
    importFile: '',
    importType: '',
    results: undefined,
  }),

  methods: {
    handleFileUpload( e, type ){
      this.importFile = e.target.files[0];
      this.importType = type;
    },
    
    uploadFile() {
      this.error = '';
      this.results = undefined;
      if (this.uploading) {
        return;
      }

      this.uploading = true;
   
      let formData = new FormData();
      formData.append('file', this.importFile);

      fetch(`${process.env.VUE_APP_API_BASE_URL}/dashboard/import/${this.importType}`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${store.getters['account/bearerToken']}`,
            'X-Organization-Code': store.getters['account/organizationCode'],
          },
          body: formData
      })
      .then( async (response) => {
        if (!response.ok) {
          this.error = await response.text();
        } else {
          this.results = await response.json();
        }
        this.uploading = false;
      })
      .catch( () => {
        this.uploading = false;
      });
    },
  }
};
</script>